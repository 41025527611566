import React from "react"
import Layout from "../components/Layout"
import solutionsearch from "../images/solution-search.png"

import reviewsfeatureone from "../images/reviews-feature-one.png"
import reviewsfeaturetwo from "../images/reviews-feature-two.png"
import solutionanalytics from "../images/solution-analytics.png"
import Finalctablock from "../components/Finalctablock"

const reviews = () => {
  return (
    <Layout>
      <div className="container">
        <section className="reviews-hero">
          <div className="reviews-herotxt">
            <h1>Build years worth of trust in just a couple of months </h1>
            <p>
              Not all your happy customers leave reviews, InsideHero unlocks
              your silent majority by making it easier for your customers to
              rate your business.
            </p>
            <a href="">
              <button className="btn btn-main btn-hero">BOOK DEMO</button>
            </a>
          </div>

          <img src={solutionsearch} className="reviews-heroimg" alt="" />
        </section>
      </div>

      <div className="product-grad">
        <div className="container">
          <section className="product-underhero">
            <div className="point reviews-point-one">
              <h4>Be found first</h4>
              <p>
                By having a healthy ecosystem of current reviews, you’ll show up
                at the top of local search results, so leads will find you
                first.
              </p>
            </div>

            <div className="point reviews-point-two">
              <h4>Get chosen</h4>
              <p>
                90% of consumers say that reviews impact their purchasing
                decision. Earn their trust before they contact you.
              </p>
            </div>

            <div className="point reviews-point-three">
              <h4>Increase your revenue</h4>
              <p>
                A healthy influx of positive reviews means less leads choosing
                your competition, and more revenue for you.
              </p>
            </div>
          </section>
        </div>
      </div>

      <div className="container feature-stripe-cont">
        <div className="solutionstripefeaturetop"></div>
        <section className="features-grid reviews-feature-one">
          <img src={reviewsfeatureone} alt="" />
          <div className="featurestxt">
            <h3>Get more customer reviews, effortlessly</h3>
            <p>
              With InsideHero send an SMS or Email review request to your
              customers. 30 seconds is all it takes for them to leave a review,
              making it effortless to get new reviews on Google, Facebook and
              industry sites your customers trust.
            </p>
          </div>
        </section>
        <div className="solutionstripefeaturebottom"></div>
      </div>

      <div className="container feature-stripe-cont">
        <section className="features-grid reviews-feature-two">
          <div className="featurestxt">
            <h3>Monitor and respond to your reviews</h3>
            <p>
              Be alerted when you get a bad review, and fix things early. All
              your reviews from Google and Facebook come into InsideHero.
            </p>
          </div>
          <img src={reviewsfeaturetwo} alt="" />
        </section>
      </div>

      <div className="container feature-stripe-cont">
        <div className="solutionstripefeaturetop"></div>
        <section className="features-grid reviews-feature-three">
          <img src={solutionanalytics} alt="" />
          <div className="featurestxt">
            <h3>Monitor business performance</h3>
            <p>
              Gain insights into your business performance, see what’s working
              well and fix what’s not.
            </p>
          </div>
        </section>
        <div className="solutionstripefeaturebottom"></div>
      </div>

      <div className="container feature-stripe-cont">
        <div className="container">
          <h2>Features</h2>
          <section className="product-features">
            <div className="product-features-point product-features-point-one">
              <h4>Notifications</h4>
              <p>Be alerted and respond when a review is posted.</p>
            </div>

            <div className="product-features-point product-features-point-two">
              <h4>Templates</h4>
              <p>
                Send personalised messages or make it easier with customisable
                templates.
              </p>
            </div>

            <div className="product-features-point product-features-point-three">
              <h4>Leaderboard</h4>
              <p>
                Drive competition by letting your team compete in sending out
                review invites.
              </p>
            </div>

            <div className="product-features-point product-features-point-four">
              <h4>150+ review sites</h4>
              <p>
                Get new reviews on any site where your customers are searching.
              </p>
            </div>
          </section>
        </div>
      </div>

      <div className="container feature-stripe-cont">
        <div className="container">
          <div className="solutionstripefeaturetop"></div>
          <Finalctablock />
        </div>
      </div>
    </Layout>
  )
}

export default reviews
